import './../App.css';
import {Helmet} from "react-helmet";


export default function SuccessPage() {

    return (
        <div className="page-success">
            <Helmet>
                <script>
                    {"fbq('track', 'Lead');"}
                </script>
            </Helmet>
            <span className="title">Реєстрація успішна.</span>
            <span className="content">Очікуйте на зворотній звʼязок від адміністратора.</span>
        </div>
    );
}