import './../App.css';
import {useController, useForm} from "react-hook-form";
import {useState} from "react";
import {redirect, useNavigate} from "react-router-dom";

const TELEGRAM_BOT_TOKEN = '8161163631:AAG-kmG-nZ8l6X5x2-rBewmqd1a9yxTto7Q';
const CHAT_ID = '8002226665';

export default function Root() {
    const navigate = useNavigate();

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const {handleSubmit, control} = useForm({
        initialValues: {
            name: '', phone: '', city: ''
        }, mode: 'onChange', reValidateMode: "onChange",
    });

    const {field: fieldCity} = useController({control, name: 'city'});
    const {field: fieldName} = useController({control, name: 'name'});
    const {field: fieldPhone} = useController({control, name: 'phone'});


    const onSubmit = (data) => {
        const message = `Імʼя: ${data.name}\nНомер телефону: ${data.phone}\nМісто: ${data.city}`;

        setIsLoading(true);
        fetch(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                chat_id: CHAT_ID,
                text: message,
                parse_mode: 'HTML' // Use 'Markdown' or 'HTML' if you want to format text
            })
        })
            .then(response => {
                if (!response.ok) {
                    setIsSuccess(false);
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(() => {
                setIsSuccess(true);

            })
            .catch(() => {
                setIsSuccess(false);
            })
            .finally(() => {
                setIsLoading(false);
                setIsSubmitted(true);
            });
    };


    const renderResult = () => {
        if(isSuccess){
            navigate('/thanks');
            return null;
        }

        return (
            <div className="popup-backdrop">
                <div className="popup">
                    <span className="title">Помилка.</span>
                    <span className="content">Презавантажте сторінку та спробуйте ще раз.</span>
                </div>
            </div>
        );
    }

    return (<div className="app">
        {isSubmitted && renderResult()}
        <div className="blurred">
            <form className="container" onSubmit={handleSubmit(onSubmit)}>
                <div className="header">
                    <span className="text">Зареєструйся в клубі</span>
                    <span className="text-colored">Champion і Simple</span>
                </div>
                <div className="rounded-block-1">
                    та отримай подарунковий код на улюблений провайдер
                </div>
                <div className="additional-text">
                    Для отримання локації на використання
                    подарункового коду, пройди реєстрацію
                    нижче та очікуй на зворотній звʼязок від
                    адміністратора.
                </div>
                <div className="main-form">
                    <label>
                        <span>Місто</span>
                        <input {...fieldCity} type="text" maxLength={100} required/>
                    </label>
                    <label>
                        <span>Ім'я</span>
                        <input {...fieldName} type="text" maxLength={100} required/>
                    </label>
                    <label>
                        <span>Номер телефону</span>
                        <input {...fieldPhone} type="text" maxLength={20} required/>
                    </label>
                </div>
                <button onSubmit={handleSubmit(onSubmit)} className="submit-button" disabled={isLoading}>
                    Реєстрація
                </button>
            </form>
        </div>
    </div>);
}